.splitter-layout {
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* .splitter-layout .layout-pane {
  position: relative;
  flex: 0 0 auto;
  overflow: auto;
} */

.splitter-layout .layout-pane.layout-pane-primary {
  flex: 1 1 auto;
  display: grid;
  grid-template-rows: 35px 1fr 0;
}

/* .splitter-layout > .layout-splitter {
  flex: 0 0 auto;
  width: 4px;
  height: 100%;
  cursor: col-resize;
  background-color: #1e2332;
}

.splitter-layout .layout-splitter:hover {
  background-color: #555;
} */

.layout-splitter {
  flex: 0 0 auto;
  width: 100%;
  height: 5px;
  cursor: row-resize;
  background-color: #1e2332;
}

.layout-splitter:hover {
  background-color: #555;
}

.splitter-layout.layout-changing {
  cursor: col-resize;
}

.splitter-layout.layout-changing > .layout-splitter {
  background-color: #555;
}

.splitter-layout.splitter-layout-vertical {
  flex-direction: column;
}

.splitter-layout.splitter-layout-vertical.layout-changing {
  cursor: row-resize;
}

.splitter-layout.splitter-layout-vertical > .layout-splitter {
  width: 100%;
  height: 4px;
  cursor: row-resize;
}

.reflex-element {
  overflow: hidden !important;
}

.reflex-splitter {
  height: 5px !important;;
  background-color: #1e2332 !important;
  border-top: 1px solid #1e2332 !important;
  border-bottom: 1px solid #1e2332 !important;
  z-index: 1 !important;
}

.reflex-splitter:hover {
  background-color: #414866 !important;
}